/* You can add global styles to this file, and also import other style files */
// @import "~bootstrap/dist/css/bootstrap.css"
html {
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
}

.tp-banner {
  display: none;
}

.ut-mute {
  z-index: 5000;
  -webkit-font-smoothing: antialiased;
  -webkit-transition-delay: 0s;
  -webkit-transition-duration: 0.2s;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: linear;
  background-attachment: scroll;
  background-image: "assets/images/ut-unmute.png";
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-bottom-color: rgb(255, 110, 0);
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0px;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(255, 110, 0);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(255, 110, 0);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgb(255, 110, 0);
  border-top-style: none;
  border-top-width: 0px;
  bottom: 20px;
  box-sizing: border-box;
  color: rgb(255, 110, 0);
  cursor: pointer;
  display: block;
  font-family: ralewayregular, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  height: 25px;
  left: 951.5px;
  letter-spacing: 0.5px;
  line-height: 22px;
  margin-bottom: 0px;
  margin-left: -12px;
  margin-right: 0px;
  margin-top: 0px;
  outline-color: rgb(255, 110, 0);
  outline-style: none;
  outline-width: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  position: absolute;
  text-align: center;
  text-decoration: none solid rgb(255, 110, 0);
  text-indent: -9999px;
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: color;
  transition-timing-function: linear;
  vertical-align: baseline;
  width: 25px;
}

/*--------------------
-----Main Styles------
----------------------*/
body {
  font-family: "Open Sans", sans-serif, Arial;
  font-size: 18px;
  color: #7a7c7f;
  font-weight: 300;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Lato, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0.02em;
  margin: 0 0 20px;
}

p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 20px;
}

.lead {
  font-size: 20px;
  line-height: 28px;
  font-weight: 100;
  letter-spacing: 0.02em;
  margin-top: 40px;
  margin-bottom: 40px;
}

a,
a:hover,
a:focus {
  outline: none !important;
  text-decoration: none;
}

a {
  color: #0f1d41;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #0f1d41;
  text-decoration: underline;
}

a:focus,
.btn:focus {
  outline: none;
}

.color-bg a {
  color: #fff;
  text-decoration: underline;
}

.color-bg a:hover,
.color-bg a:focus {
  color: #fff;
  text-decoration: none;
}

.primary {
  color: #0f1d41;
}

/*----------------------
---Main Layout Styles---
------------------------*/
.img-center {
  margin: 0 auto;
}

.padding-top-bottom {
  padding: 100px 0;
}

.padding-top {
  padding: 100px 0 0;
}

.navbar {
  margin: 0;
  border-radius: 0;
}

.page-title {
  margin: 0;
  padding-top: 80px;
  font-family: "Dosis", Arial, sans-serif;
  font-size: 64px;
  line-height: 78px;
  font-weight: 400;
  letter-spacing: 0.03em;
  text-align: center;
}

.page-subtitle {
  margin: 0;
  margin-top: 10px;
  font-size: 24px;
  line-height: 21px;
  font-weight: 300;
  font-style: italic;
  letter-spacing: 0.02em;
  text-align: center;
}

.page-subtitle:before {
  position: relative;
  top: 14px;
  margin-right: 5px;
  content: "\201C";
  font-size: 52px;
}

.page-subtitle:after {
  position: relative;
  top: 32px;
  margin-left: 5px;
  content: "\201D";
  font-size: 52px;
}

.section-title {
  position: relative;
  margin-bottom: 40px;
  font-size: 42px;
  line-height: 64px;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif, Arial;
}

.section-title:after {
  display: block;
  content: "";
  width: 100px;
  height: 4px;
  position: absolute;
  left: 50%;
  bottom: -15px;
  margin-left: -50px;
  background: #0f1d41;
  border-radius: 3px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.08);
}

.section-description {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 60px;
}

.subsection-title {
  text-align: center;
  margin-top: 80px;
}

.subsection-description {
  text-align: center;
  margin-bottom: 60px;
}

/*------------------
----- Buttons ------
--------------------*/

.btn-alpata {
  border: none;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  padding: 15px 30px;
  display: inline-block;
  margin: 20px 0;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: 600;
  outline: none;
  position: relative;
  background: #0f1d41;
  color: #fff;
  box-shadow: 0 4px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.btn-alpata:hover,
.btn-alpata:focus {
  color: #fff;
  box-shadow: 0 3px rgba(0, 0, 0, 0.07);
  top: 1px;
  text-decoration: none;
}

.btn-alpata:active {
  color: #fff;
  box-shadow: 0 2px rgba(0, 0, 0, 0.07);
  top: 2px;
  text-decoration: none;
}

.btn-alpata.white {
  background: transparent;
  border: 2px solid #fff;
  box-shadow: none;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.btn-alpata.white:hover,
.btn-alpata.white:focus,
.btn-alpata.white:active {
  background: #fff;
  color: #0f1d41;
  top: 0;
  box-shadow: 0 3px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.btn-alpata [class*="fa"] {
  margin-right: 10px;
}

/*----------------
----- Colors -----
------------------*/

.white-bg {
  background: #fff;
}

.gray-bg {
  background: #f3f3f3;
}

.color-bg {
  background: #0f1d41;
}

.dark-bg {
  background: #4e5961;
}

.light-typo {
  color: #fff;
}

.color-bg.light-typo .section-title:after {
  background: #fff;
}

/*----------------------
----Navigation Styles---
------------------------*/
#main-nav .navbar-toggle {
  border: 1px solid #4e5961;
  border-radius: 0;
}

#main-nav .icon-bar {
  background: #4e5961;
}

.navbar-brand,
.navbar-brand:hover,
.navbar-brand:active,
.navbar-brand:focus {
  position: relative;
  padding: 0 10px;
  font-size: 28px;
  line-height: 60px;
  font-weight: 600;
  color: #777;
  font-variant: small-caps;
  letter-spacing: 0.02em;
  -webkit-transition: font-size 0.2s ease-out;
  transition: font-size 0.2s ease-out;
  overflow: hidden;
}

#main-nav {
  background: #fff;
  /*background-color: rgba(255,255,255,0.7);*/
  color: #4e5961;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

#main-nav.scrolled .navbar-toggle {
  color: #5aa9ce;
  border-color: #5aa9ce;
}

#main-nav.scrolled .navbar-toggle .icon-bar {
  background: #5aa9ce;
}

#main-nav .container {
  padding: 15px 20px;
  -webkit-transition: padding 0.3s ease-out;
  transition: padding 0.3s ease-out;
}

#main-nav .nav a {
  position: relative;
  z-index: 1;
}

#main-nav .nav a,
#main-nav .nav a:active,
#main-nav .nav a:hover {
  padding: 0px 5px;
  font-size: 15px;
  line-height: 54px;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  background: transparent;
  color: #4e5961;
  opacity: 0.5;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

#main-nav .nav .active > a,
#main-nav .nav a:hover {
  opacity: 1;
}

#main-nav .nav a:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 10%;
  content: "";
  width: 80%;
  height: 3px;
  background: #0f1d41;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

#main-nav .nav a:hover:before,
#main-nav .nav .active a:before {
  bottom: 7px;
  opacity: 1;
}

.no-csstransforms #main-nav .nav a:before {
  display: none;
}

.no-csstransforms #main-nav .nav a:hover,
.no-csstransforms #main-nav .nav .active a {
  color: #5aa9ce;
}

#main-nav li {
  background: transparent;
  margin: 0 5px;
  border: 2px solid transparent;
}

#main-nav .dropdown-menu a {
  font-size: 14px;
}

.site-logo {
  max-height: 54px;
}

.logo-small {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  opacity: 1;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.to-top {
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -14px;
  left: 10px;
  width: 30px;
  height: 30px;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  background: #eee;
  opacity: 0;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar-brand.scrollto:hover .logo-small {
  opacity: 0;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar-brand.scrollto:hover .to-top {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/*----------------------
-----Header Styles------
------------------------*/

.jumbotron {
  background: #0f1d41;
  color: #fff;
  padding: 0;
  margin-bottom: 0;
}

/*-------------------------
------Call to action-------
--------------------------*/
.cta {
  padding: 50px 0 30px;
}

.cta .cta-message p {
  padding: 15px 0 20px;
  font-size: 22px;
  line-height: 32px;
}

.cta .cta-message strong {
  font-size: 24px;
  letter-spacing: 0.02em;
}

.cta .cta-button {
  padding: 0;
}

/*--------------------
---Features Styles---
----------------------*/
.features .section-description {
  margin-bottom: 80px;
}

.features .icon {
  width: 80px;
  height: 80px;
  text-align: center;
  background: #cccfd5;
  color: #fff;
  font-size: 28px;
  line-height: 80px;
  border-radius: 50%;
  box-shadow: 0 3px rgba(0, 0, 0, 0.05);
  -webkit-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out;
}

.features .media:hover .icon {
  background: #0f1d41;
}

.features .icon .icon-1 {
  width: 80px;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease-out 0.2s;
  transition: all 0.3s ease-out 0.2s;
}

.features .icon .icon-2 {
  width: 80px;
  position: relative;
  top: -80px;
  opacity: 0;
  -webkit-transform: scale(2);
  transform: scale(2);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.features .media:hover .icon .icon-1 {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.features .media:hover .icon .icon-2 {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease-out 0.2s;
  transition: all 0.3s ease-out 0.2s;
}

.features .media {
  margin-bottom: 80px;
}

.features .media-body p {
  font-size: 16px;
}

.features .media h4 {
  -webkit-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}

.features .media:hover h4 {
  color: #0f1d41;
}

/*--------------------
--CTA iPhones Styles--
----------------------*/
.cta-iphones h2 {
  margin-top: 60px;
}

.cta-iphones .iphones-wrapper {
  max-width: 450px;
  margin: 0 auto;
  position: relative;
}

.cta-iphones .iphone-portrait-frame {
  position: relative;
  width: 39.5%;
}

.cta-iphones .iphone-landscape-frame {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 84%;
}

.cta-iphones .scrollimation .iphone-landscape-frame {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: -webkit-transform 0.5s ease-in;
  transition: transform 0.5s ease-in;
}

.touch .cta-iphones .scrollimation .iphone-landscape-frame {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.cta-iphones .scrollimation .iphone-landscape-frame.in {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.cta-iphones .iphone-portrait-screen {
  position: absolute;
  width: 87%;
  height: 72.7%;
  top: 13%;
  left: 6.5%;
  overflow: hidden;
}

.cta-iphones .iphone-landscape-screen {
  position: absolute;
  width: 72.7%;
  height: 87%;
  top: 6.5%;
  right: 13%;
  overflow: hidden;
}

/*-----------------------
-----Services Styles-----
-------------------------*/
.services {
  margin-top: 80px;
  margin-bottom: 40px;
}

.services .icon {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  text-align: center;
  background: #0f1d41;
  line-height: 150px;
  font-size: 48px;
  color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  margin-bottom: 40px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.services .item:hover .icon {
  font-size: 38px;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05);
}

.services .icon:after {
  display: block;
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  top: 0;
  left: 0;
  border-radius: 50%;
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.08);
  background: transparent;
  -webkit-transition: box-shadow 0.3s ease-out;
  transition: box-shadow 0.3s ease-out;
}

.services .item:hover .icon:after {
  box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.08);
}

.services h3 {
  font-size: 21px;
  font-weight: 400;
  text-transform: uppercase;
  -webkit-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}

.services .item:hover h3 {
  color: #0f1d41;
}

.services p {
  padding: 0 20px;
  font-size: 16px;
  line-height: 21px;
}

/*--------------------
----Process Styles----
----------------------*/

.process-list {
  margin: 0;
  padding: 0;
  background: transparent;
  list-style: none;
  text-align: center;
  width: 100%;
}

.process-list li {
  display: inline-block;
  background: transparent;
  margin: 20px 0;
  width: 19%;
  vertical-align: top;
}

.process-list .icon {
  position: relative;
  display: block;
  text-align: center;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
  background: #bbb;
  border-radius: 50%;
  font-size: 48px;
  line-height: 120px;
  color: #fff;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.process-list .icon:hover {
  font-size: 36px;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.process-list li:nth-child(1) .icon {
  background: #eee;
}

.process-list li:nth-child(2) .icon {
  background: #ddd;
}

.process-list li:nth-child(3) .icon {
  background: #ccc;
}

.process-list li:nth-child(4) .icon {
  background: #bbb;
}

.process-list li:nth-child(5) .icon {
  background: #aaa;
}

.process-list li.colored .icon {
  background: #0f1d41;
}

.scrollimation .process-list li:nth-child(1) {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4);
  transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4);
}

.touch .scrollimation .process-list li:nth-child(1),
.scrollimation.in .process-list li:nth-child(1) {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.scrollimation .process-list li:nth-child(2) {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4)
    0.4s;
  transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4) 0.4s;
}

.touch .scrollimation .process-list li:nth-child(2),
.scrollimation.in .process-list li:nth-child(2) {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.scrollimation .process-list li:nth-child(3) {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4)
    0.8s;
  transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4) 0.8s;
}

.touch .scrollimation .process-list li:nth-child(3),
.scrollimation.in .process-list li:nth-child(3) {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.scrollimation .process-list li:nth-child(4) {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4)
    1.2s;
  transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4) 1.2s;
}

.touch .scrollimation .process-list li:nth-child(4),
.scrollimation.in .process-list li:nth-child(4) {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.scrollimation .process-list li:nth-child(5) {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4)
    1.6s;
  transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4) 1.6s;
}

.touch .scrollimation .process-list li:nth-child(5),
.scrollimation.in .process-list li:nth-child(5) {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.process-list h4 {
  font-size: 21px;
  font-weight: 400;
}

.process-box {
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
}

.process-line {
  position: absolute;
  width: 80%;
  margin: 0 10%;
  height: 0;
  top: 80px;
  border-top: 3px dotted #0f1d41;
  z-index: -1;
}

.scrollimation .process-line {
  width: 0;
  -webkit-transition: width 2.5s ease-out;
  transition: width 2.5s ease-out;
}

.touch .scrollimation .process-line,
.scrollimation.in .process-line {
  width: 80%;
}

/*-----------------------
---iPad Slider Styles----
-------------------------*/

.ipad-frame {
  display: block;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
}

.ipad-screen {
  display: block;
  position: absolute;
  top: 7.7%;
  left: 9.375%;
  width: 81.25%;
  height: 92.3%;
  overflow: hidden;
}

.flexslider {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
}

.ipad-slider .flex-direction-nav .flex-prev,
.ipad-slider .flex-direction-nav .flex-next {
  background: #0f1d41;
  background-image: none;
  display: inline;
  text-indent: 0;
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  padding: 0;
  padding-left: 12px;
  margin: 0;
  margin-top: -22px;
  font-size: 42px;
  line-height: 38px;
  color: #fff;
  border-radius: 5px;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.ipad-slider .flex-direction-nav .flex-next {
  text-align: right;
  padding-right: 12px;
}

.ipad-slider .flex-direction-nav a:hover,
.ipad-slider .flex-direction-nav a:active,
.ipad-slider .flex-direction-nav a:focus {
  text-decoration: none;
}

.ipad-slider .flex-direction-nav a:hover {
  background: #363b46;
}

.ipad-slider:hover .flex-next {
  opacity: 1;
  right: 5px;
}

.ipad-slider:hover .flex-prev {
  opacity: 1;
  left: 5px;
}

/*-----------------------
---Macbook Video Styles--
-------------------------*/

.macbook-frame {
  position: relative;
}

.macbook-screen {
  display: block;
  position: absolute;
  top: 7%;
  left: 12.04%;
  width: 75.91%;
}

/*-------------------
-----Team Styles-----
---------------------*/
.team-member {
  margin-bottom: 30px;
}

.member-thumb {
  text-align: center;
}

.member-thumb h4 {
  font-size: 21px;
  margin: 10px 0;
}

.member-thumb .title {
  font-size: 18px;
  margin: 10px 0;
  color: #0f1d41;
}

.team-member .member-details {
  margin: 20px 0 0 20px;
  position: relative;
  padding: 30px;
  padding-left: 100px;
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.08);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.team-member:hover .member-details {
  background: #0f1d41;
  color: #fff;
}

.team-member.right .member-details {
  margin: 20px 20px 0 0;
  box-shadow: -5px 5px 0 rgba(0, 0, 0, 0.08);
}

.team-member .member-details:after {
  display: block;
  content: "";
  position: absolute;
  left: -18px;
  top: 30px;
  width: 0px;
  height: 0px;
  background: transparent;
  border: 20px solid transparent;
  border-left: 20px solid #f5f5f5;
  box-shadow: -3px 0 0 rgba(0, 0, 0, 0.08);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: border-left-color 0.3s ease-out;
  transition: border-left-color 0.3s ease-out;
}

.team-member.right .member-details:after {
  position: absolute;
  left: auto;
  right: -17px;
  border-left: 20px solid transparent;
  border-right: 20px solid #f5f5f5;
  box-shadow: 3px 0 0 0 rgba(0, 0, 0, 0.08);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: border-right-color 0.3s ease-out;
  transition: border-right-color 0.3s ease-out;
}

.team-member:hover .member-details:after {
  border-left-color: #0f1d41;
}

.team-member.right:hover .member-details:after {
  border-left-color: transparent;
  border-right-color: #0f1d41;
}

.team-member .member-details:before {
  display: block;
  content: "\201D";
  position: absolute;
  left: 20px;
  top: 50px;
  font-size: 120px;
  font-weight: 800;
  line-height: 60px;
  font-family: Arial;
  color: #0f1d41;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.team-member:hover .member-details:before {
  color: #fff;
}

.team-member .member-socials {
  list-style: none;
  margin: 0;
  padding: 0;
}

.team-member .member-socials li {
  display: inline;
  text-align: center;
  margin: 0 2px;
}

.team-member .member-socials li a {
  display: inline-block;
  padding: 0;
  min-width: 40px;
  height: 40px;
  font-size: 21px;
  line-height: 40px;
  color: #0f1d41;
  border-radius: 5px;
  box-shadow: 0 3px 0 0 transparent;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.team-member:hover .member-socials li a {
  color: #fff;
}

.team-member .member-socials li a:hover {
  background: #fff;
  color: #0f1d41;
  box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
}

.chart-wrapper {
  background: #0f1d41;
  display: block;
  width: 180px;
  height: 180px;
  padding-top: 20px;
  margin: 0 auto 30px;
  border-radius: 50%;
}

.chart {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 150px;
  margin-bottom: 50px;
  text-align: center;
}

.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.percent {
  display: inline-block;
  line-height: 140px;
  font-size: 38px;
  font-weight: 300;
  color: #fff;
  z-index: 2;
}

.percent:after {
  content: "%";
  margin-left: 0.1em;
  font-size: 24px;
}

.skills h2 {
  color: #4e5961;
  font-size: 32px;
}

/*----------------------
-----Portfolio Styles---
------------------------*/
#filter-works ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}

#filter-works ul li {
  display: inline-block;
  padding: 0;
  line-height: 24px;
  background: transparent;
  margin: 0;
  margin-left: 5px;
  margin-bottom: 10px;
}

#filter-works ul li a {
  display: block;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 5px;
  border: 2px solid transparent;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

#filter-works ul li a,
#filter-works ul li a:active,
#filter-works ul li a:hover {
  line-height: 24px;
  background: #fff;
  color: #4e5961;
  text-decoration: none;
}

#filter-works ul li a:hover,
#filter-works ul li.active a {
  color: #fff;
  background: #0f1d41;
}

.projects-container .row {
  -webkit-transition: height 0.5s ease-out;
  transition: height 0.5s ease-out;
}

.project-item {
  position: relative;
  margin-bottom: 30px;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.project-item.filtered {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0.2;
  cursor: default;
}

.no-opacity .project-item.filtered {
  display: none;
}

.project-item.filtered a {
  cursor: default;
}

.project-item.filtered .enlarge,
.project-item.filtered .link,
.project-item.filtered .overlay-mask,
.project-item.filtered .project-title {
  display: none;
}

.project-thumb {
  display: block;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.scrollimation .project-thumb {
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-out, -webkit-transform 0.4s ease-out;
  transition: opacity 0.4s ease-out, transform 0.4s ease-out;
}

.touch .scrollimation .project-thumb,
.scrollimation .project-thumb.in {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}

.project-thumb .overlay-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0f1d41;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 1;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.project-thumb:hover .overlay-mask {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.project-thumb .enlarge,
.project-thumb .link {
  display: inline-block;
  margin: 0;
  margin-top: -25px;
  font-size: 50px;
  line-height: 50px;
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  height: 50px;
  width: 64px;
  top: 40%;
  left: 50%;
  text-align: center;
  z-index: 3;
}

.project-thumb .enlarge {
  margin-left: -84px;
  -webkit-transform: translateX(-200px);
  transform: translateX(-200px);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.project-thumb:hover .enlarge {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: all 0.3s ease-out 0.3s;
  transition: all 0.3s ease-out 0.3s;
}

.project-thumb .link {
  margin-left: 20px;
  -webkit-transform: translateX(200px);
  transform: translateX(200px);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.project-thumb:hover .link {
  -webkit-transform: translate(0);
  transform: translate(0);
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: all 0.3s ease-out 0.6s;
  transition: all 0.3s ease-out 0.6s;
}

.project-thumb .enlarge.centered,
.project-thumb .link.centered {
  margin-left: -32px;
  -webkit-transform: translateY(-200px);
  transform: translateY(-200px);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  display: none;
}

.project-thumb:hover .enlarge.centered,
.project-thumb:hover .link.centered {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.project-thumb .project-title {
  display: block;
  width: 100%;
  position: absolute;
  bottom: -100px;
  background: #fff;
  margin: 0;
  padding: 18px;
  font-size: 16px;
  font-weight: 300;
  color: #777;
  text-align: center;
  z-index: 2;
  -webkit-transition: bottom 0.4s ease-out, color 0.2s ease-out;
  transition: bottom 0.4s ease-out, color 0.2s ease-out;
}

.project-thumb:hover .project-title {
  bottom: 0;
  -webkit-transition: bottom 0.3s ease-out 0.1s, color 0.2s ease-out 0s;
  transition: bottom 0.3s ease-out 0.1s, color 0.2s ease-out 0s;
}

.project-thumb .project-title:hover {
  color: #0f1d41;
}

/*Load More Projects*/
.loaded-item {
  display: none;
}

/*Project Preview*/
.preview-content {
  display: none;
}

#project-preview {
  display: none;
}

#project-preview .loader {
  display: block;
  // background: url(../assets/loader.html) center center no-repeat;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 35%;
  left: 50%;
  margin-left: -18px;
}

#project-preview .imac-frame {
  display: block;
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: 0 auto 30px;
  overflow: hidden;
}

#project-preview .imac-screen {
  background: transparent;
  display: block;
  position: absolute;
  top: 5.5%;
  left: 4.5%;
  width: 91%;
  height: 66%;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

#project-preview .imac-screen.loaded {
  opacity: 1;
}

.flexslider {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
}

.imac-slider .flex-direction-nav .flex-prev,
.imac-slider .flex-direction-nav .flex-next {
  background: #0f1d41;
  background-image: none;
  display: inline;
  text-indent: 0;
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  padding: 0;
  padding-left: 12px;
  margin: 0;
  margin-top: -22px;
  font-size: 42px;
  line-height: 38px;
  color: #fff;
  border-radius: 5px;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.imac-slider .flex-direction-nav .flex-next {
  text-align: right;
  padding-right: 12px;
}

.imac-slider .flex-direction-nav a:hover,
.imac-slider .flex-direction-nav a:active,
.imac-slider .flex-direction-nav a:focus {
  text-decoration: none;
}

.imac-slider .flex-direction-nav a:hover {
  background: #363b46;
}

.imac-slider:hover .flex-next {
  opacity: 1;
  right: 5px;
}

.imac-slider:hover .flex-prev {
  opacity: 1;
  left: 5px;
}

.imac-slider .flex-direction-nav .flex-disabled,
.ipad-slider .flex-direction-nav .flex-disabled {
  display: none;
}

.project-preview .close-preview {
  display: inline-block;
  padding: 10px;
  font-size: 48px;
  line-height: 48px;
  font-weight: 400;
  margin: 5px 0;
  text-decoration: none;
}

/*-------------------
------ Prices -------
---------------------*/

.pricing .item {
  position: relative;
  top: 20px;
  background: rgba(255, 255, 255, 1);
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 7px;
  color: #777;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.pricing .item.featured {
  top: 0;
  padding-top: 50px;
  padding-bottom: 50px;
}

.pricing .item:hover,
.pricing .item.featured {
  background: #0f1d41;
  color: #fff;
}

.pricing .item h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: uppercase;
}

.pricing .item.featured h2 {
  margin-bottom: 10px;
}

.pricing .item p {
  margin-bottom: 5px;
  font-weight: 400;
}

.pricing .item .icon {
  display: inline-block;
  margin-bottom: 30px;
  background: #ccc;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  font-size: 48px;
  line-height: 120px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.pricing .item.featured .icon,
.pricing .item:hover .icon {
  color: #0f1d41;
  background: #fff;
}

.pricing .item .btn-alpata {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 16px;
  padding: 10px 20px;
}

.pricing .item.featured .btn-alpata,
.pricing .item:hover .btn-alpata {
  background: #fff;
  color: #0f1d41;
}

.pricing .item .price {
  font-size: 48px;
  font-weight: 100;
  line-height: 48px;
  margin-bottom: 30px;
}

.pricing .item.featured .price {
  margin-bottom: 40px;
}

/*-------------------
-----News Styles-----
---------------------*/
.timeline {
  position: relative;
  margin-bottom: 100px;
  z-index: 1;
}

.timeline:before {
  display: block;
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  left: 1px;
  top: 0;
  border-right: 1px solid #0f1d41;
  z-index: -1;
}

.timeline:after {
  display: block;
  content: "";
  position: absolute;
  width: 50%;
  height: 100px;
  left: 1px;
  bottom: -105px;
  border-right: 1px dashed #0f1d41;
  z-index: -1;
}

.timeline .date-title {
  text-align: center;
  margin: 70px 0 50px;
}

.timeline .date-title span {
  padding: 15px 30px;
  font-size: 21px;
  font-weight: 400;
  color: #fff;
  background: #0f1d41;
  border-radius: 5px;
}

.news-item {
  padding-bottom: 45px;
}

.news-item.right {
  float: right;
  margin-top: 40px;
}

.news-item .news-content {
  margin: 20px 30px 0 0;
  position: relative;
  padding: 30px;
  padding-left: 100px;
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: -5px 5px 0 rgba(0, 0, 0, 0.08);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.news-item:hover .news-content {
  background: #0f1d41;
  color: #fff;
}

.news-item.right .news-content {
  margin: 20px 0 0 30px;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.08);
}

.news-item .news-content:after {
  display: block;
  content: "";
  position: absolute;
  top: 50px;
  right: -40px;
  width: 0px;
  height: 0px;
  background: transparent;
  border: 20px solid transparent;
  border-left: 20px solid #f5f5f5;
  -webkit-transition: border-left-color 0.3s ease-out;
  transition: border-left-color 0.3s ease-out;
}

.news-item.right .news-content:after {
  position: absolute;
  left: -40px;
  right: auto;
  border-left: 20px solid transparent;
  border-right: 20px solid #f5f5f5;
  -webkit-transition: border-right-color 0.3s ease-out;
  transition: border-right-color 0.3s ease-out;
}

.news-item:hover .news-content:after {
  border-left-color: #0f1d41;
}

.news-item.right:hover .news-content:after {
  border-left-color: transparent;
  border-right-color: #0f1d41;
}

.news-item .news-content:before {
  display: block;
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  right: -55px;
  top: 60px;
  background: #0f1d41;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out;
}

.news-item.right .news-content:before {
  left: -55px;
  right: auto;
}

.news-content .date {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 10px;
  text-align: center;
  color: #0f1d41;
  -webkit-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}

.news-item:hover .news-content .date {
  color: #fff;
}

.news-content .date p {
  margin: 0;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
}

.news-content .date small {
  margin: 0;
  font-size: 26px;
  font-weight: 300;
  line-height: 24px;
}

.news-content .news-title {
  font-size: 24px;
  font-weight: 300;
}

.news-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
}

.news-content .read-more,
.news-content .read-more:hover,
.news-content .read-more:active,
.news-content .read-more:focus {
  padding: 10px 0;
  text-decoration: none;
  font-size: 16px;
  color: #7a7c7f;
  line-height: 24px;
}

.news-item:hover .news-content .read-more,
.news-item:hover .news-content .read-more:hover,
.news-item:hover .news-content .read-more:active,
.news-item:hover .news-content .read-more:focus {
  color: #fff;
}

.news-content .read-more {
  -webkit-transition: padding 0.3s ease-out;
  transition: padding 0.3s ease-out;
}

.news-content .read-more:hover {
  padding-left: 7px;
}

.news-content .read-more:after {
  content: "\f054";
  padding-left: 15px;
  font-family: "FontAwesome";
  font-size: 21px;
  line-height: 21px;
  color: #0f1d41;
  vertical-align: middle;
  -webkit-transition: padding 0.3s ease-out;
  transition: padding 0.3s ease-out;
}

.news-content .read-more:hover:after {
  padding-left: 20px;
}

.news-item:hover .news-content .read-more:after {
  color: #fff;
}

.news-content .news-media {
  position: absolute;
  width: 80px;
  bottom: -45px;
  right: 40px;
  border-radius: 8px;
}

.news-content .news-media img {
  border-radius: 8px;
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.news-content .news-media a {
  display: block;
  text-decoration: none;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}

.news-content .news-media a:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.news-content .news-media a:after {
  content: "\f065";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  font-family: FontAwesome;
  font-size: 32px;
  line-height: 80px;
  text-align: center;
  color: #0f1d41;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.2s ease-out 0.1s;
  transition: all 0.2s ease-out 0.1s;
}

.news-content .news-media.video a:after {
  content: "\f04b";
}

.news-content .news-media a:hover:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.news-content .news-media.gallery {
  box-shadow: 4px 4px 0 #bbb, 8px 8px 0 #ddd;
}

/*----------------------
-----Clients Styles-----
------------------------*/
.clients .client {
  max-width: 300px;
  margin: 0 auto 20px;
  padding: 30px;
  overflow: hidden;
}

.client img {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  -webkit-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
}

.client img:hover {
  -webkit-transform: scale(1.2) rotate(-5deg);
  transform: scale(1.2) rotate(-5deg);
}

/*----------------------
-----Contact Styles-----
------------------------*/
.contact-item h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.contact-item .icon {
  display: block;
  font-size: 48px;
  color: #0f1d41;
  text-shadow: -2px 2px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.contact-item .icon:hover {
  color: #0f1d41;
  -webkit-transform: scale(1.3) translateY(-10px);
  transform: scale(1.3) translateY(-10px);
}

.bl_form {
  margin: 30px 0 0;
}

.bl_form input {
  padding-top: 15px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 0px rgba(0, 0, 0, 0.2);
  border: none;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.lb_wrap .lb_label.top,
.lb_wrap .lb_label.bottom {
  left: 66px !important;
}

.lb_wrap .lb_label.left {
  left: 0;
}

.lb_label {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  color: #ccc;
}

.no-placeholder .lb_label {
  display: none;
}

.lb_label.active {
  color: #aaa;
}

.contact-form .form-group label,
#hr-form .form-group label {
  display: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 100;
  text-transform: uppercase;
}

.contact-form.no-placeholder .form-group label,
#hr-form.no-placeholder .form-group label {
  display: block;
}

.contact-form .controls,
#hr-form .controls {
  padding: 0;
  margin-top: 40px;
}

.contact-form.no-placeholder .controls,
#hr-form.no-placeholder .controls {
  margin-top: 0;
}

.contact-form .form-control,
#hr-form .form-control {
  display: inline;
  background: #fff;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
  height: 52px;
  font-size: 18px;
  line-height: 32px;
  color: #aaa;
  font-weight: 400;
  padding-left: 64px;
  vertical-align: top;
}

.contact-form .form-group.half-width,
#hr-form .form-group.half-width {
  width: 40%;
  float: left;
}

.contact-form .form-group,
#hr-form .form-group {
  position: relative;
}

.contact-form .form-group [class*="fa"],
#hr-form .form-group [class*="fa"] {
  display: block;
  width: 64px;
  position: absolute;
  top: 0;
  left: 5px;
  color: #eee;
  font-size: 24px;
  line-height: 52px;
  text-align: center;
  font-weight: 300;
  -webkit-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}

.contact-form .form-group [class*="fa"].active,
#hr-form .form-group [class*="fa"].active {
  color: #ccc;
}

.contact-form.no-placeholder .form-group [class*="fa"],
#hr-form.no-placeholder .form-group [class*="fa"] {
  top: 30px;
}

.contact-form textarea.form-control,
#hr-form textarea.form-control {
  height: auto;
  max-width: 100%;
  min-width: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 14px;
  vertical-align: top;
}

.contact-form .form-control:focus,
#hr-form .form-control:focus {
  outline: none;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
}

.contact-form .error-message,
#hr-form .error-message {
  padding: 5px 0;
  position: absolute;
  top: -35px;
  right: 0;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: #ff3345;
  z-index: 10;
}

.contact-form.no-placeholder .error-message,
#hr-form.no-placeholder .error-message {
  top: 0;
}

/*----------------------
-----Footer Styles------
------------------------*/

#main-footer {
  padding: 40px 0 20px;
  box-shadow: 0 -4px 0 rgba(0, 0, 0, 0.1);
}

.social-links {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.social-links li {
  display: inline-block;
  margin: 0 10px 5px;
  border-radius: 3px;
  box-shadow: 0 4px 0 transparent;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.social-links li a {
  display: block;
  color: #fff;
  font-size: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.social-links li:hover {
  background: #fff;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
  color: #0f1d41;
}

.social-links li:hover a {
  color: #0f1d41;
}

/*-----------------------
-----Scrollimations------
------------------------*/
.scrollimation.fade-in {
  opacity: 0;
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}

.scrollimation.fade-in.in {
  opacity: 1;
}

.scrollimation.scale-in {
  opacity: 0;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition: -webkit-transform 0.6s
      cubic-bezier(0.17, 0.89, 0.67, 1.57),
    opacity 0.6s ease-out;
  transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.67, 1.57),
    opacity 0.6s ease-out;
}

.scrollimation.scale-in.in {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.scrollimation.fade-right {
  opacity: 0;
  -webkit-transform: translateX(-100px);
  -moz-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
  transform: translateX(-100px);
  -webkit-transition: -webkit-transform 0.4s ease-out, opacity 0.4s ease-out;
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
}

.scrollimation.fade-right.in {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
  height: 295px;
}

.scrollimation.fade-left {
  opacity: 0;
  -webkit-transform: translateX(100px);
  -moz-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
  -webkit-transition: -webkit-transform 0.4s ease-out, opacity 0.4s ease-out;
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
}

.scrollimation.fade-left.in {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.scrollimation.fade-up {
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: -webkit-transform 0.4s ease-out, opacity 0.4s ease-in;
  transition: transform 0.4s ease-out, opacity 0.4s ease-in-out;
}

.scrollimation.fade-up.in {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.scrollimation.d1 {
  transition-delay: 0.2s;
}

.scrollimation.d2 {
  transition-delay: 0.4s;
}

.scrollimation.d3 {
  transition-delay: 0.6s;
}

.scrollimation.d4 {
  transition-delay: 0.8s;
}

.touch .scrollimation.fade-in {
  opacity: 1;
}

.touch .scrollimation.scale-in {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.touch .scrollimation.fade-right {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.touch .scrollimation.fade-left {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.touch .scrollimation.fade-up {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

/*----------------------
-----Media Queries------
------------------------*/
@media (max-width: 991px) {
  .navbar-header,
  #main-nav .nav {
    width: 100%;
    text-align: center;
  }

  .navbar-brand {
    float: none;
  }

  .news-content .news-media {
    bottom: -55px;
  }
}

@media (min-width: 768px) {
  /*Submenu*/
  #main-nav .nav li {
    position: relative;
  }

  #main-nav .nav li ul {
    display: block !important;
    list-style: none;
    position: absolute;
    margin: 0;
    padding: 10px 0;
    top: 100%;
    right: -20px;
    border-radius: 5px;
    background: #0f1d41;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  #main-nav .nav li:hover ul {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
  }

  #main-nav .nav li ul li {
    margin: 0;
  }

  #main-nav .nav li ul li a,
  #main-nav .nav li ul li a:hover,
  #main-nav .nav li ul li a:active,
  #main-nav .nav li ul li a:focus {
    display: block;
    margin: 0;
    padding: 7px 20px;
    width: 100%;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;
    white-space: nowrap;
    text-align: center;
    color: #fff;
    opacity: 0.7;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
  }

  #main-nav .nav li ul li.active a,
  #main-nav .nav li ul li a:hover {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    opacity: 1;
  }

  #main-nav .nav li:hover > a {
    opacity: 1;
  }

  #main-nav .nav li:hover a:before {
    bottom: 7px;
    opacity: 1;
  }

  #main-nav .nav li.submenu > a:before,
  #main-nav .nav li ul li a:before {
    display: none;
  }

  #main-nav .nav li.submenu > a:after {
    content: "\f0d7";
    font-family: FontAwesome;
    margin-left: 5px;
    color: #0f1d41;
  }
}

@media (max-width: 767px) {
  body,
  p {
    font-size: 16px;
    line-height: 21px;
  }

  h1,
  .skills h2 {
    font-size: 24px;
    line-height: 28px;
  }

  h2 {
    font-size: 21px;
    line-height: 24px;
  }

  .page-title {
    font-size: 42px;
    line-height: 52px;
  }

  .page-subtitle {
    font-size: 18px;
    line-height: 21px;
  }

  .page-subtitle:before {
    top: 7px;
    font-size: 32px;
  }

  .page-subtitle:after {
    top: 21px;
    font-size: 32px;
  }

  .section-title {
    font-size: 28px;
    line-height: 36px;
  }

  .section-description {
    font-size: 18px;
  }

  .cta .cta-message p,
  .cta .cta-message strong {
    font-size: 18px;
    line-height: 24px;
  }

  .navbar-header,
  #main-nav .nav {
    width: auto;
  }

  .navbar-brand {
    float: left;
  }

  #main-nav .nav a {
    text-align: center;
    border-bottom: 1px solid #f5f5f5;
  }

  #main-nav .nav a:before {
    display: none;
  }

  #main-nav .nav li.submenu > a:after {
    content: "\f0d7";
    font-family: FontAwesome;
    margin-left: 5px;
    color: #0f1d41;
  }

  #main-nav .nav li ul {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #main-nav .nav li ul li a,
  #main-nav .nav li ul li a:hover,
  #main-nav .nav li ul li a:active,
  #main-nav .nav li ul li a:focus {
    display: block;
    margin: 0;
    width: 100%;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;
    text-align: center;
  }

  .cta .cta-message {
    text-align: center;
  }

  .services .item {
    margin-bottom: 60px;
  }

  .process-list li {
    display: block;
    width: 100%;
    text-align: center;
  }

  .process-line {
    display: none;
  }

  .team-member .member-thumb {
    margin-bottom: 30px;
  }

  .team-member {
    margin-bottom: 60px;
  }

  .team-member .member-thumb img {
    width: 300px;
    max-width: 100%;
  }

  .team-member .member-details:after,
  .team-member.right .member-details:after {
    left: 50%;
    margin-left: -20px;
    top: -40px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fafafa;
    box-shadow: none;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: border-bottom-color 0.3s ease-out;
    transition: border-bottom-color 0.3s ease-out;
  }

  .team-member:hover .member-details:after,
  .team-member.right:hover .member-details:after {
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #0f1d41;
  }

  .team-member .member-details,
  .team-member.right .member-details {
    margin: 20px 0 0 0;
  }

  .skills h2 {
    margin-bottom: 60px;
  }

  .project-item .project-thumb {
    max-width: 400px;
    margin: 0 auto;
  }

  .pricing .item {
    top: 0;
    margin: 0 auto 40px;
    max-width: 400px;
  }

  .news-item.right {
    margin-top: 0;
  }

  .news-item .news-content,
  .news-item.right .news-content {
    margin: 40px 0 0;
  }

  .news-item .news-content:after,
  .news-item .news-content:before {
    display: none;
  }
}

@media (max-width: 479px) {
  .news-item .news-content {
    padding-left: 80px;
  }

  .news-content .date {
    width: 60px;
  }

  .news-content .date p {
    font-size: 38px;
    line-height: 38px;
  }

  .news-content .date small {
    font-size: 21px;
    line-height: 21px;
  }

  .news-content .news-title {
    font-size: 21px;
  }

  .news-content p {
    line-height: 21px;
  }
}

.dil {
  background: transparent;
  /*margin: 0 5px;*/
  border: 2px solid transparent;
  /*width:8%;*/
  float: right;
  font-size: 18px;
  font-family: "Open Sans", sans-serif, Arial;
  height: 54px;
  padding: 14px 0;
  margin-right: 0px;
}

.googleMapDiv {
  display: inline-block;
  padding: 15px;
  position: relative;
  width: 100% !important;
  height: 350px;
}

.googleMap {
  width: 100%;
  height: 350px;
}

.googleMapLink {
  color: #0f1d41;
  text-align: left;
  font-weight: bold;
}

#main-nav {
  width: 100%;
  position: absolute;
  top: 100%;
}

#main-nav.elm-fixed {
  position: fixed;
  top: 0px;
}

.slider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  // background-image: url("../../assets/images/overlay-pattern.png");
}

a.slide-down {
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid #ffffff;
  font-size: 15px;
  text-align: center;
  color: #ffffff !important;
  padding: 5px 10px;
  text-decoration: none;
  width: 100px;
  display: inline-block;
}

.portfolio {
  padding-bottom: 200px;
}

.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 10px;
  background: #0f1d41;
  outline: 0 !important;
  border: 0;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
